.stepperContainer {
  width: 58% !important;
  padding: 20px !important;
  background-color: #FFFFFF !important;
  border-radius: 30px !important;
}

@media (max-width: 736px) {
  .stepperContainer {
    width: 100% !important;
  }
}