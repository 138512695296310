@import url('https://fonts.googleapis.com/css2?family=Prompt&family=Yantramanav&display=swap');

html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

* {
    font-family: 'Prompt', sans-serif;
}

.header * {
    color: white !important;
}

.header {
    width: 100%;
    padding: 10px 10%;
    box-sizing: border-box;
}

.headerLogo {
    width: 200px;
}

.bgImage {
    width: 100% !important;
    height: 500px !important;
    background-size: cover !important;
}

.inputRounded .MuiOutlinedInput-root {
    border-radius: 30px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.termSelect {
    justify-content: flex-start !important;
    width: 48% !important;
    padding-right: 10px !important;
    border: 1px solid #c6c0c0 !important;
    border-radius: 30px !important;
}

.termInputValue {
    margin: 0 auto !important;
}

.MuiSlider-thumb {
    color: #545AF5;
}

.MuiSlider-rail {
    height: 10px !important;
    background-color: #E2E0E0 !important;
    color: #545AF5;
}

.MuiSlider-track {
    color: #545AF5;
}

.language-icon {
    width: 28px;
    height: 28px;
}

.calculate {
    display: flex;
    flex-direction: column;
    width: 320px !important;
    padding: 20px 0;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    color: #FFFFFF;
    background-color: #1B263B;
}

@media (max-width: 720px) {
    .header {
        padding: 10px 5%;
    }

    .headerLogo {
        width: 120px;
    }

    .bgImage {
        height: 370px !important;
    }

    .termSelect {
        width: 48% !important;
    }
}

@media (max-width: 720px) {
    .termSelect {
        width: 200px !important;
    }
}
