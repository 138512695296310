.generalBlock {
  display: flex !important;
  justify-content: center !important;
  padding: 40px 0 !important;
}

.settingsBlock {
  display: flex !important;
  justify-content: stretch !important;
  width: 60% !important;
  height: 100% !important;
  border-radius: 30px !important;
  box-shadow: 0 8px 32px rgba(19, 10, 46, 0.1) !important;
  background-color: #ffffff !important;
}

.additionalInfo {
  color: #767676 !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.promoCode {
  display: flex !important;
  margin-top: 30px !important;
}

.promoCodeBtn {
  width: 100%;
  padding: 14px 10px !important;
  margin-left: 20px !important;
  border-radius: 30px !important;
  font-size: 14px !important;
}

.loader {
  width: 25px !important;
  height: 25px !important;
}

.regionTitle {
  display: flex;
  align-items: center;
}

.warningIcon {
  width: 22px;
  height: 22px;
  margin-left: 20px;
}

@media (max-width: 1200px) {
  .settingsBlock {
    width: 80% !important;
  }
}

@media (max-width: 1024px) {
  .settingsBlock {
    width: 100% !important;
  }

  .creditSelections {
    flex-direction: column !important;
  }

  .additionalInfo {
    font-size: 14px !important;
  }

  .promoCode {
    flex-direction: column !important;
  }

  .promoCodeBtn {
    margin: 30px 0 0 0 !important;
    font-size: 12px !important;
  }
}

@media (max-width: 900px) {
  .promoCodeBtn {
    padding: 16px 10px !important;
    font-size: 12px !important;
  }
}